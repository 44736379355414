.sectionB {
  padding: 2%;
  min-width: 300px;
  /* margin-top: 500px; */
  background-color: black;
}

.cardB-left,
.cardB-right {
  border: none;
}

/* .cardB-right-text > h2 {
  font-family: 'Fredoka One' !important;
  font-size: 2rem;
} */

/* .cardB-right-text > p {
  font-family: 'Fredoka One' !important;
  font-size: 1rem;
} */


.cardB-right-body {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
  background-color: black;
  color: white;
}

/* .cardB-right-title {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
} */

.sectionB-button {
  background-color: white !important;
  /* border: 5px solid rgb(241, 209, 15); */
}
