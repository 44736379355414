.edit-user-button-save-container {
  display: flex !important;
  justify-content: space-between !important;
}

.edit-user-button-save {
  background-color: rgb(30, 30, 30);
  color: white;
  width: 100%;
  margin: 40px 0px 0px !important;
}
