.error-page {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error-page-container > h1 {
  color: rgb(241, 209, 15);
  font-weight: bold;
  font-size: 5rem;
}